import React from "react";
import { Link } from "gatsby";
import { Container } from "../components/container";

const companyLinks = [
  { to: "/what-we-do", name: "What we do" },
  { to: "/who-are-we", name: "Who are we" },
  { to: "/endorsements", name: "Endorsements" },
];

const productLinks = [
  {
    to: "/grammar-resources",
    name: "Grammar",
  },
  {
    to: "/spelling-resources",
    name: "Spelling",
  },
  {
    to: "/writing-resources",
    name: "Writing",
  },
  {
    to: "/maths-resources",
    name: "Maths",
  },
  {
    to: "/science-resources",
    name: "Science",
  },
];

const headerStyle =
  "text-sm font-semibold tracking-wider text-gray-400 uppercase leading-5";

const linkStyle = "text-base text-gray-300 leading-6 hover:text-white";
export const Footer = () => {
  return (
    <div className="py-12 bg-gray-800">
      <Container>
        <div className="grid grid-cols-2 gap-8 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div className="mt-12 md:mt-0">
              <h4 className={headerStyle}>Contact</h4>
              <ul>
                <li>
                  <Link to="/#contact" className={linkStyle}>
                    Online
                  </Link>
                </li>
              </ul>
              <h4 className={headerStyle + " normal-case"}>Peter Boddy</h4>
              <dl className="mt-2 text-base text-gray-400 leading-6">
                <div>
                  <dt className="sr-only">Email</dt>
                  <dl className="break-words">
                    <a href="mailto:primaryassertivementoring@gmail.com">
                      primaryassertivementoring@gmail.com
                    </a>
                  </dl>
                </div>
                <div className="mt-1">
                  <dt className="sr-only">Phone number</dt>
                  <dl>07810 125 494</dl>
                </div>
              </dl>
              <h4 className={headerStyle + " normal-case"}>Eamonn Farrar</h4>
              <dl className="mt-2 text-base text-gray-400 leading-6">
                <div>
                  <dt className="sr-only">Email</dt>
                  <dl>
                    <a href="mailto:eamonnfarrar@hotmail.com">
                      eamonnfarrar@hotmail.com
                    </a>
                  </dl>
                </div>
                <div className="mt-1">
                  <dt className="sr-only">Phone number</dt>
                  <dl>07951 391 388</dl>
                </div>
              </dl>
            </div>
            <div>
              <h4 className={headerStyle}>Company</h4>
              <ul className="mt-4">
                {companyLinks.map((link) => (
                  <li className="mt-4" key={link.name}>
                    <Link to={link.to} className={linkStyle}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div className="mt-12 md:mt-0">
              <h4 className={headerStyle}>Products</h4>
              <ul className="mt-4">
                {productLinks.map((link) => (
                  <li className="mt-4" key={link.name}>
                    <Link to={link.to} className={linkStyle}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h4 className={headerStyle}>Order</h4>
              <ul>
                <li>
                  <Link to="/order" className={linkStyle}>
                    Online
                  </Link>
                </li>
                <li className="mt-4">
                  <a
                    className={linkStyle}
                    href="/assets/Product_Order_Form_2020.pdf"
                  >
                    Download form
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pt-8 mt-8 border-t border-gray-700">
          <p className="text-base leading-6 text-gray-400">
            &copy; {new Date().getFullYear()} Assertive Mentoring LTD All rights
            reserved.
          </p>
        </div>
      </Container>
    </div>
  );
};
