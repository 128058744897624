import React from "react";
import { Link } from "gatsby";
import Transition from "./transition";
import logo from "../images/logo.svg";
import { useComponentVisible } from "../hooks/use-outside";
import { Container } from "../components/container";
import { Dropdown } from "../components/dropdown";

const navLinks = [
  { to: "/endorsements", name: "Endorsements" },
  { to: "/order", name: "Order" },
];

const dropdownLinks = [
  { to: "/what-we-do", name: "What we do" },
  { to: "/who-are-we", name: "Who are we" },
];

// Separate this from the normal links for styling in mobile view
const contactLink = { to: "/#contact", name: "Contact" };

export const Nav = () => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  return (
    <div className="py-6 mx-auto max-w-screen-xl ">
      <div className="relative z-10 bg-white lg:max-w-2xl lg:w-full">
        <Container>
          <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/">
                  <img
                    className="w-auto h-8 text-indigo-600 fill-current sm:h-10"
                    src={logo}
                    alt="Logo"
                  />
                </Link>
                <div className="flex items-center -mr-2 md:hidden">
                  <button
                    aria-label="Open mobile menu"
                    type="button"
                    onClick={() => setIsComponentVisible(true)}
                    className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  >
                    <svg
                      className="w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:ml-10 md:pr-4">
              <span className="ml-8">
                <Dropdown
                  title="Company"
                  options={dropdownLinks}
                  textClassName="font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
                />
              </span>
              {navLinks.map((link) => (
                <Link
                  to={link.to}
                  key={link.name}
                  className="ml-8 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
                >
                  {link.name}
                </Link>
              ))}
              <Link
                to={contactLink.to}
                key={contactLink.name}
                className="ml-8 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >
                {contactLink.name}
              </Link>
            </div>
          </nav>
        </Container>

        {/* Mobile menu, show/hide based on menu open state. */}

        {/* Entering: "duration-150 ease-out" */}
        {/*   From: "opacity-0 scale-95" */}
        {/*   To: "opacity-100 scale-100" */}
        {/* Leaving: "duration-100 ease-in" */}
        {/*   From: "opacity-100 scale-100" */}
        {/*   To: "opacity-0 scale-95" */}
        <Transition
          show={isComponentVisible}
          enter="transition ease-out duration-150"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute inset-x-0 top-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-md">
              <div className="overflow-hidden bg-white rounded-lg shadow-xs">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img className="w-auto h-8" src={logo} alt="Logo" />
                  </div>
                  <div className="-mr-2">
                    <button
                      ref={ref}
                      aria-label="Close mobile menu"
                      onClick={() => setIsComponentVisible(false)}
                      type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  {navLinks.concat(dropdownLinks).map((link) => {
                    return (
                      <Link
                        to={link.to}
                        key={link.name}
                        className="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                      >
                        {link.name}
                      </Link>
                    );
                  })}
                </div>
                <div>
                  <Link
                    to={contactLink.to}
                    className="block w-full px-5 py-3 font-medium text-center text-gray-900 bg-gray-50 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700 transition duration-150 ease-in-out"
                  >
                    {contactLink.name}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};
