import { useState, useEffect, useRef } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref: React.RefObject<HTMLButtonElement> = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref?.current?.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}
