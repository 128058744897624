import React from "react";

const baseStyle =
  "flex w-full items-center border border-transparent text-white font-medium bg-indigo-600 hover:bg-indigo-800 focus:outline-none focus:border-indigo-700 focus:shadow-outline-gray active:bg-indigo-700 transition ease-in-out duration-150";

const outlineStyle =
  "flex w-full items-center border border-indigo-300 text-indigo-700 bg-white hover:text-indigo-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-indigo-800 active:bg-indigo-50 transition ease-in-out duration-150";

const sizes = {
  xsmall: "px-2.5 py-1.5 text-xs leading-4 rounded",
  small: "px-3 py-2 text-sm leading-4 rounded-md",
  medium: "px-4 py-2 text-sm leading-5 rounded-md",
  large: "px-4 py-2 text-base leading-6 rounded-md",
  xlarge: "px-6 py-3 text-base leading-6 rounded-md",
  xxlarge:
    "px-8 py-3 text-base font-medium md:py-4 md:px-10 md:text-lg md:font-semibold leading-6 rounded-md",
};

export const Button = ({
  children,
  isLoading,
  className = "",
  size,
  type,
  href,
  isOutline,
}: {
  children: React.ReactNode;
  className?: string;
  size?: keyof typeof sizes;
  type?: "submit" | "reset" | "button";
  href?: string;
  isOutline?: boolean;
  isLoading?: boolean;
}) => {
  const buttonSizeStyles = !size ? sizes.medium : sizes[size];

  const buttonMainStyle = isOutline ? outlineStyle : baseStyle;

  return (
    <span className={`inline-flex rounded-md shadow-sm ${className}`}>
      {href ? (
        <a href={href} className={`${buttonMainStyle} ${buttonSizeStyles}`}>
          {children}
        </a>
      ) : (
        <button
          type={type}
          className={`${buttonMainStyle} ${buttonSizeStyles}`}
        >
          <div className={`${isLoading ? "spinner" : ""}`}>{children}</div>
        </button>
      )}
    </span>
  );
};
