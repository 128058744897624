import React from "react";
import { Link } from "gatsby";
import Transition from "../components/transition";
import { useComponentVisible } from "../hooks/use-outside";

export const Dropdown = ({
  title,
  options,
  textClassName = "",
}: {
  title: string;
  options: Array<{ name: string; to: string }>;
  textClassName?: string;
}) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          ref={ref}
          type="button"
          onClick={() => setIsComponentVisible(!isComponentVisible)}
          className={`inline-flex justify-center w-full bg-white leading-5 focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150 ${textClassName}`}
        >
          {title}
          <svg
            className="w-5 h-5 ml-2 -mr-1"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {/* Dropdown panel, show/hide based on dropdown state. */}

      {/* Entering: "transition ease-out duration-100" */}
      {/*   From: "transform opacity-0 scale-95" */}
      {/*   To: "transform opacity-100 scale-100" */}
      {/* Leaving: "transition ease-in duration-75" */}
      {/*   From: "transform opacity-100 scale-100" */}
      {/*   To: "transform opacity-0 scale-95" */}

      <Transition
        show={isComponentVisible}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute right-0 w-56 mt-2 shadow-lg origin-top-right rounded-md">
          <div className="bg-white rounded-md shadow-xs">
            <div className="py-1">
              {options.map((option) => (
                <Link
                  key={option.name}
                  to={option.to}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                >
                  {option.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};
